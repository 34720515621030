<template>
  <v-container>
    <v-radio-group v-model="conclusionType" class="conclusion-type">
      <v-row
        class="conclusion-row-sub-title"
        :class="{ disabled: conclusionType != conclusionTypeOptions.Symptoms }"
      >
        <v-radio
          class="fit-width-checkbox"
          :value="conclusionTypeOptions.Symptoms"
        >
          <template v-slot:label>
            <v-card-subtitle class="conclusion-sub-title"
              >Bệnh nhân này nghi ngờ bị</v-card-subtitle
            >
            <v-text-field
              v-model="RightEyeText"
              dense
              label="Text"
              outlined
            ></v-text-field
            ><v-card-subtitle>ở mắt phải và/hoặc</v-card-subtitle>
            <v-text-field
              v-model="LeftEyeText"
              dense
              label="Text"
              outlined
            ></v-text-field>
            <v-card-subtitle>ở mắt trái .</v-card-subtitle>
          </template>
        </v-radio>
        <v-card-subtitle class="conclusion-row-sub-title-1">
          Xin chuyển bác sĩ theo dõi tiếp .
        </v-card-subtitle>
      </v-row>
      <v-row
        class="conclusion-row-sub-title"
        :class="{ disabled: conclusionType != conclusionTypeOptions.Normal }"
      >
        <v-radio :value="conclusionTypeOptions.Normal">
          <template v-slot:label>
            <v-card-subtitle class="conclusion-sub-title"
              >Bệnh nhân thực hiện theo chỉ định của đơn kính và đơn thuốc. Tái
              khám theo lịch đã hẹn (nếu có).</v-card-subtitle
            >
          </template>
        </v-radio>
      </v-row>
    </v-radio-group>
    <v-bottom-navigation
      grow
      height="50"
      absolute
      class="elevation-0 exam-bottom-controller"
      hide-on-scroll
    >
      <v-btn
        ripple
        color="#0065FF"
        class="px-4 py-3 white--text pl-10 pr-10"
        @click="handleSaveConclusion"
        v-bind:disabled="isFinishExamination"
      >
        {{ $t("common.SaveData") }}
      </v-btn>
    </v-bottom-navigation>
  </v-container>
</template>
<script>
import ConclusionService from "@/services/conclusion";
import { ConclusionType, EyeType } from "@/plugins/constant";
import i18n from "@/plugins/i18n";

export default {
  components: {},
  data() {
    return {
      conclusionID: null,
      episodeRecordID: this.$route.params.episodeRecordID,
      conclusionType: null,
      RightEyeText: "",
      LeftEyeText: "",
      conclusionTypeOptions: ConclusionType,
      isFinishExamination: false,
    };
  },
  computed: {},
  mounted() {
    this.renderConclusionDetail();
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    showSuccess(message) {
      this.$toast.success(message);
    },
    async renderConclusionDetail() {
      var result = await ConclusionService.getConclusionDetail(
        this.episodeRecordID
      );
      if (!result) return;
      var { id, conclusionType, conclusionSuggestion } = result;
      this.conclusionType = conclusionType;
      this.conclusionID = id;
      this.renderSuggestionTexts(conclusionSuggestion);
    },
    renderSuggestionTexts(suggestions) {
      if (!suggestions) {
        suggestions = [];
      }
      var rightSuggestion = suggestions.find(
        (i) => i.conclusionCodePosition == EyeType.Right
      );
      if (rightSuggestion) {
        this.RightEyeText = rightSuggestion.codeDescription;
      }
      var leftSuggestion = suggestions.find(
        (i) => i.conclusionCodePosition == EyeType.Left
      );
      if (leftSuggestion) {
        this.LeftEyeText = leftSuggestion.codeDescription;
      }
    },
    async handleSaveConclusion() {
      var bodyData = {
        episopdeRecordID: this.episodeRecordID,
        conclusionType: this.conclusionType,
        conclusionSuggestions: [],
      };
      if (this.conclusionType == ConclusionType.Symptoms) {
        bodyData.conclusionSuggestions = [
          {
            codeDescription: this.RightEyeText,
            conclusionCodePosition: EyeType.Right,
          },
          {
            codeDescription: this.LeftEyeText,
            conclusionCodePosition: EyeType.Left,
          },
        ];
      }
      var result;
      if (this.conclusionID != null) {
        result = await ConclusionService.UpdateConclusion(
          this.episodeRecordID,
          bodyData
        );
      } else {
        result = await ConclusionService.CreateConclusion(bodyData);
      }
      if (!result || result.error) {
        this.showError(result.message);
        return;
      }
      this.showSuccess(i18n.t("message.optometristConclusion"));
    },
  },
};
</script>

<style lang="scss">
.conclusion-type {
  .disabled {
    opacity: 0.5;
  }
}
.conclusion-sub-table {
  height: 230px;
}
.conclusion-row-sub-title-1 {
  margin-left: 33px;
  color: rgba(0, 0, 0, 0.6);
  padding: 10px;
}
.conclusion-row-sub-title {
  padding: 0;
  .conclusion-sub-title {
    padding: 0;
    margin: 10px;
  }

  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding-top: 0px;
    margin-bottom: 0px;
  }
  .v-text-field__details {
    min-height: 0px;
  }
  .v-messages {
    min-height: 0px;
  }
  .fit-width-checkbox {
    width: 90%;
  }
  .v-input--selection-controls .v-input__slot > .v-label,
  .v-input--selection-controls .v-radio > .v-label {
    height: 30px;
  }
}
.exam-bottom-controller {
  background-color: #f5f5f5 !important;
  button {
    background-color: #0277bd !important;
    .v-btn__content {
      color: white !important;
      font-size: 120%;
      text-transform: uppercase;
    }
  }
  button:disabled {
    background-color: #bdbdbd !important;
    .v-btn__content {
      color: #424242 !important;
    }
  }
}
.v-item-group.v-bottom-navigation {
  display: flex;
  width: calc(100% - 16px) !important;
  bottom: 15px;
}
</style>