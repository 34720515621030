import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'
var httpClient = new HttpClient(ApiGateway.Emr);


export default {
    getConclusionDetail(episodeRecordID) {
        var query = {
            episodeRecordID
        }
        return httpClient.get(`/Conclusion/Get/{episodeRecordID}`, query);
    },
    async CreateConclusion(bodyData) {
        return httpClient.post("/Conclusion/Create", {}, bodyData);
    },
    async UpdateConclusion(episodeRecordID, bodyData) {
        return httpClient.put(`/Conclusion/Update/${episodeRecordID}`, {}, bodyData)
    },
}